<template>
  <b-modal
    id="modalUploadBankReceipt"
    :visible="modalVisibility(modalId)"
    :title="`Upload Bank Receipt`"
    @show="onShow()"
    @hidden="resetModal()"
    @ok="onOk"
    size="md"
    no-close-on-backdrop
    ok-title="Submit"
  >
    <b-row>
      <b-col>
        <base-input
          type="select"
          v-model="form.bank_name"
          label="Bank name"
          placeholder="Please select"
          :options="BankListOptions"
          :validator="$v.form.bank_name"
          :messages="localMessages"
        ></base-input>
        <base-input
          v-if="form.bank_name === 'Other'"
          v-model="form.others"
          type="text"
          attribute="Bank Name"
          label="Please state your bank name"
          :validator="$v.form.others"
          :messages="localMessages"
        ></base-input>
      </b-col>
      <b-col>
        <base-input
          type="text"
          v-model="form.bank_account"
          label="Bank Account"
          :validator="$v.form.bank_account"
          :messages="localMessages"
        ></base-input>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <label>Date & Time </label>
        <div class="form-group">
          <flat-pickr
            class="form-control"
            :class="[{ 'is-invalid': $v.form.dateTime.$error }]"
            placeholder="DD-MM-YYYY"
            v-model="form.dateTime"
            :config="datePickerConf"
          ></flat-pickr>
          <div
            class="flatpickr-error error-message"
            v-if="$v.form.dateTime.$dirty && $v.form.dateTime.$error"
          >
            <div v-if="!$v.form.dateTime.required">
              <i class="fa fa-exclamation-triangle"></i> Datetime cannot be
              empty
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <base-input
          type="text"
          v-model="form.approve_amount"
          label="Amount"
          :placeholder="invoiceTotal"
          :validator="$v.form.approve_amount"
          :messages="localMessages"
        ></base-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <base-input
          type="text"
          v-model="form.reference"
          label="Reference"
          :validator="$v.form.reference"
          :messages="localMessages"
        ></base-input>
      </b-col>
    </b-row>
    <b-row>
      <b-col> <label>Upload Bank Receipt</label></b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-file
          v-model="file"
          :class="{ 'is-invalid': $v.file.$invalid }"
          ref="file-input"
          accept=".jpg, .png, .jpeg, .pdf "
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          class="bayo-form-file w-100"
        ></b-form-file>
      </b-col>
      <b-col cols="3" v-if="file">
        <b-button @click="resetFile()" variant="danger" block> Reset </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import SystemHelper from "@/core/services/systemhelper.service";
import {
  GET_BANK_RECEIPT_LIST,
  UPLOAD_BANK_RECEIPT,
} from "@/core/services/store/modules/clientadmin/billing.module";
import { required } from "vuelidate/lib/validators";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from "moment";
// import { email, required, sameAs } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  components: { flatPickr },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);

    return {
      readOnly: true,
      modalId: "modalUploadBankReceipt",
      isBusy: true,
      bank_receipt_datetime: null,
      maxDate: maxDate,
      file: null,
      form: {
        dateTime: null,
        bank_name: "",
        bank_account: "",
        reference: "",
        approve_amount: "",
      },
      datePickerConf: {
        enableTime: true,
        dateFormat: "d-m-Y H:i:S",
        maxDate: "today",
        time_24hr: true,
        enableSeconds: true,
        minuteIncrement: 1,
        secondIncrement: 1,
      },
      invoice_id: null,
      invoiceTotal: null,
    };
  },
  validations: {
    file: required,
    form: {
      bank_name: { required },
      bank_account: { required },
      reference: { required },
      approve_amount: { required },
      dateTime: { required },
    },
  },
  computed: {
    InvoiceList() {
      const list = this.$store.state.clientadmin_billing.invoiceList;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
    CurrentInvoice() {
      let invoice = this.InvoiceList;
      invoice = invoice.find(
        (invoice) => invoice.invoice_id == this.invoice_id
      );
      return invoice;
    },
    BankListOptions() {
      const bankListReference = [
        {
          name: "Maybank",
          code: "27",
        },
        {
          name: "CIMB Bank",
          code: "35",
        },
        {
          name: "RHB Bank",
          code: "18",
        },
        {
          name: "Bank Islam",
          code: "45",
        },
        {
          name: "Bank Muamalat",
          code: "41",
        },
        {
          name: "Bank Rakyat",
          code: "02",
        },
        {
          name: "Bank Simpanan Nasional",
          code: "10",
        },
        {
          name: "Citibank",
          code: "17",
        },
        {
          name: "Hong Leong Bank",
          code: "24",
        },
        {
          name: "HSBC Bank",
          code: "22",
        },
        {
          name: "OCBC Bank",
          code: "29",
        },
        {
          name: "Public Bank",
          code: "33",
        },
        {
          name: "Affin Bank",
          code: "32",
        },
        {
          name: "AmBank",
          code: "08",
        },
        {
          name: "Agro Bank",
          code: "49",
        },
        {
          name: "Alliance Bank",
          code: "12",
        },
        {
          name: "Al-Rajhi Bank",
          code: "53",
        },
        {
          name: "Bank of China",
          code: "42",
        },
        {
          name: "Bank of America",
          code: "07",
        },
        {
          name: "Bank of Tokyo-Mitsubishi UFJ",
          code: "52",
        },
        {
          name: "BNP Paribas",
          code: "60",
        },
        {
          name: "Deutsche Bank",
          code: "19",
        },
        {
          name: "Industrial & Commercial Bank of China",
          code: "59",
        },
        {
          name: "JP Morgan Chase Bank",
          code: "48",
        },
        {
          name: "Kuwait Finance House",
          code: "47",
        },
        {
          name: "Mizuho Bank",
          code: "73",
        },
        {
          name: "Standard Chartered Bank",
          code: "14",
        },
        {
          name: "Sumitomo Mitsui Banking Corporation",
          code: "51",
        },
        {
          name: "The Royal Bank of Scotland",
          code: "46",
        },
        {
          name: "United Overseas Bank",
          code: "26",
        },
      ];

      let options = [];
      if (bankListReference.length) {
        bankListReference.forEach((bankListReference) => {
          options.push({
            label: bankListReference.name,
            value: bankListReference.name,
          });
        });
      }
      options.push({ label: "Other", value: "Other" });
      return options;
    },
  },
  methods: {
    onShow() {
      console.log("showing");
      this.invoice_id = this.$route.params.invoiceId;
      this.invoiceTotal = this.CurrentInvoice.total;
    },
    resetFile() {
      this.$refs["file-input"].reset();
    },
    onOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$v.form.$touch();
      if (this.$v.form.$anyError || this.file === null) {
        return;
      } else {
        let formData = new FormData();

        if (this.form.bank_name === "Other") {
          this.form.bank_name = this.form.others;
        }

        formData.append("invoice_id", this.invoice_id);
        formData.append("bank_receipt", this.file);
        formData.append("bank_name", this.form.bank_name);
        formData.append("bank_account", this.form.bank_account);
        formData.append("reference", this.form.reference);
        formData.append("approve_amount", this.form.approve_amount);
        let paymentType = 1;
        formData.append("payment_channel_id", paymentType);
        formData.append(
          "bank_receipt_datetime",
          moment(this.form.dateTime, "DD-MM-YYYY HH:mm:ss").format(
            "DD-MM-YYYY HH:mm"
          )
        );

        SystemHelper.confirmationDialogHandler(
          { title: "Upload Bank Receipt", html: "Are you sure?" },
          { action: UPLOAD_BANK_RECEIPT, payload: formData },
          [
            {
              action: GET_BANK_RECEIPT_LIST,
              payload: { ...this.defaultPayload, invoice_id: this.invoice_id },
            },
          ],
          this.modalId
        );
      }
    },
    resetModal() {
      this.$v.form.$reset();
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
